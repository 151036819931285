/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import 'whatwg-fetch';
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter';
import '@webcomponents/custom-elements/custom-elements.min';
import 'custom-event-polyfill';

import '../assets/css/index.css';

import CategoriaController from './controllers/Categoria.controller';
import EixoController from './controllers/Eixo.controller';
import LandingPageController from './controllers/LandingPage.controller';
import ModalController from './controllers/Modal.controller';
import PesquisaController from './controllers/Pesquisa.controller';
import PesquisaTextualController from './controllers/PesquisaTextual.controller';
import ResultadoPesquisaController from './controllers/ResultadoPesquisa.controller';
import SecretariaController from './controllers/Secretaria.controller';
import SidebarController from './controllers/Sidebar.controller';
import SidebarPesquisarController from './controllers/SidebarPesquisar.controller';
import SubprefeituraController from './controllers/Subprefeitura.controller';

class App {
  #sidebarCtrl;

  #sidebarPesquisarCtrl;

  #landingPageCtrl;

  #categoriaCtrl;

  #eixoCtrl;

  #modalCtrl;

  #pesquisaCtrl;

  #pesquisaTextualCtrl;

  #resultadoPesquisaCtrl;

  #secretariaCtrl;

  #subprefeituraCtrl;

  constructor() {
    this.#categoriaCtrl = new CategoriaController();
    this.#eixoCtrl = new EixoController();
    this.#landingPageCtrl = new LandingPageController();
    this.#modalCtrl = new ModalController();
    this.#pesquisaTextualCtrl = new PesquisaTextualController();
    this.#pesquisaCtrl = new PesquisaController();
    this.#resultadoPesquisaCtrl = new ResultadoPesquisaController();
    this.#secretariaCtrl = new SecretariaController();
    this.#sidebarCtrl = new SidebarController();
    this.#sidebarPesquisarCtrl = new SidebarPesquisarController();
    this.#subprefeituraCtrl = new SubprefeituraController();
  }

  async init() {
    await this.#categoriaCtrl.carregarDados();
    await this.#eixoCtrl.carregarDados();
    await this.#secretariaCtrl.carregarDados();
    await this.#subprefeituraCtrl.carregarDados();
    this.#categoriaCtrl.criarComponentes();
    this.#eixoCtrl.criarComponentes();
    this.#secretariaCtrl.criarComponentes();
    this.#subprefeituraCtrl.criarComponentes();
    this.#pesquisaTextualCtrl.criarComponentes();
  }

  exibir() {
    if (this.#pesquisaCtrl.id) {
      this.#pesquisaCtrl.pesquisarPorId(this.#pesquisaCtrl.id);
    }
    document.querySelector('body').style.display = 'flex';
  }
}

(async () => {
  try {
    console.log('Portal de Devolutivas - Versão 1.1.0-rc1');
    const app = new App();
    await app.init();
    app.exibir();
  } catch (err) {
    console.error(err);
  }
})();
