export default class HttpService {
  constructor() {
    this.api_url = process.env.API_URL;
  }

  async request(resource) {
    return fetch(`${this.api_url}${resource}`);
  }

  async post(resource, data = {}) {
    return fetch(`${this.api_url}${resource}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  }

  async get(resource) {
    return fetch(`${this.api_url}${resource}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }
}
