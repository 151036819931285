/* eslint-disable class-methods-use-this */
import Subprefeitura from '../model/Subprefeitura';
import SubprefeituraService from '../services/Subprefeitura.service';
import Store from '../Store';

export default class SubprefeituraController {
  #service;

  constructor() {
    this.#service = new SubprefeituraService();
  }

  async carregarDados() {
    const subprefeituras = [];
    const res = await this.#service.listarTodos();
    res.forEach((row) => {
      const subprefeitura = new Subprefeitura(row.id, row.nome);
      subprefeituras.push(subprefeitura);
    });
    Store.data.subprefeituras = subprefeituras;
  }

  async criarComponentes() {
    import('../components/Subprefeitura.component');
  }
}
