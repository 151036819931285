/* eslint-disable class-methods-use-this */
import Eixo from '../model/Eixo';
import EixoService from '../services/Eixo.service';
import Store from '../Store';

export default class EixoController {
  #service;

  constructor() {
    this.#service = new EixoService();
  }

  async carregarDados() {
    const eixos = [];
    const res = await this.#service.listarTodos();
    res.forEach((row) => {
      const eixo = new Eixo(row.id, row.nome);
      eixos.push(eixo);
    });
    Store.data.eixos = eixos;
  }

  async criarComponentes() {
    import('../components/Eixo.component');
  }
}
