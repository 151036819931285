export default class Secretaria {
    #id;

    #sigla;
  
    constructor(id, sigla) {
      this.#id = id;
      this.#sigla = sigla;
    }
  
    get id() {
      return this.#id;
    }

    get sigla() {
      return this.#sigla;
    }
  }
  