import LandingPageView from '../views/LandingPage.view';
import ResultadoPesquisaView from '../views/ResultadoPesquisa.view';

export default class ResultadoPesquisaController {
  #view;

  #landingPageView;

  #modalView;

  constructor() {
    this.#view = new ResultadoPesquisaView();
    this.#landingPageView = new LandingPageView();
    this.configurarLinkVoltar();
  }

  configurarLinkVoltar() {
    document.getElementById('link-voltar').addEventListener('click', () => this.#landingPageView.show());
  }
}
