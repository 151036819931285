/* eslint-disable max-len */
/* eslint-disable class-methods-use-this */
export default class CategoriaView {
  static getCategoriasUnicas(sugestoes) {
    const categoriasArray = sugestoes.map((sugestao) => sugestao.tema.categoria.nome);
    const categoriasUnicasSet = new Set(categoriasArray);
    const categorias = Array.from(categoriasUnicasSet);
    return categorias.sort().map((categoria) => `<span class="contribuicao__categoria">${categoria}</span>`).join('');
  }
}
