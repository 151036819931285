/* eslint-disable class-methods-use-this */
import Store from '../Store';
import CategoriaView from './Categoria.view';

export default class ResultadoPesquisaView {
  show() {
    document.getElementById('landing-page').style.display = 'none';
    document.getElementById('resultado-pesquisa').style.display = 'flex';
    document.querySelector('.header__logo img').style.display = 'block';

    const contribuicoes = document.getElementById('resultado-pesquisa__resultado__contribuicoes');
    contribuicoes.innerHTML = '';

    if (Store.error) {
      this.exibirErroOutros();
    } else if (this.nenhumaContribuicaoEncontrada()) {
      this.exibirErroNaoEncontrado();
    } else {
      this.ocultarErroNaoEncontrado();
      this.ocultarErroOutros();
      this.definirExibicaoMaisRegistros();
      this.exibirContribuicoes(contribuicoes);
    }
  }

  exibirContribuicoes(contribuicoes) {
    Store.data.contribuicoes.forEach((contribuicao) => {
      const contribuicaoElem = document.createElement('div');
      contribuicaoElem.classList.add('contribuicao');
      contribuicaoElem.innerHTML = `
          <div class="contribuicao__body">
            <div class="contribuicao__body__left">
              <span class="contribuicao__nome--label">Nome</span>
              <span class="contribuicao__nome--valor">${contribuicao.nomeMunicipe}</span>
            </div>
            <div class="contribuicao__body__middle">
              <span class="contribuicao__canal--label">Canal</span>
              <span class="contribuicao__canal--valor">${contribuicao.canal.nome}</span>
            </div>
            <div class="contribuicao__body__right">
              ${CategoriaView.getCategoriasUnicas(contribuicao.sugestoes)}
            </div>
          </div>
          <div class="contribuicao__footer">
            <span class="contribuicao__footer--link" data-id="${contribuicao.id}">Clique para ver mais sobre essa devolutiva</span>
          </div>
        `;
      contribuicoes.appendChild(contribuicaoElem);
    });
  }

  definirExibicaoMaisRegistros() {
    if (this.possuiMaisContribuicoes()) {
      this.exibirMaisRegistros();
    } else {
      this.ocultarMaisRegistros();
    }
  }

  possuiMaisContribuicoes() {
    return Store.data.contribuicoes.length % 10 === 0 && Store.data.offset > 0;
  }

  nenhumaContribuicaoEncontrada() {
    return Store.data.contribuicoes.length < 1;
  }

  ocultarErroNaoEncontrado() {
    document.querySelector('.resultado-pesquisa__erro-nao-encontrado').style.display = 'none';
  }

  exibirErroNaoEncontrado() {
    document.querySelector('.resultado-pesquisa__erro-nao-encontrado').style.display = 'block';
    this.ocultarMaisRegistros();
  }

  exibirErroOutros() {
    document.querySelector('.resultado-pesquisa__erro-outros').style.display = 'block';
    this.ocultarMaisRegistros();
  }

  ocultarErroOutros() {
    document.querySelector('.resultado-pesquisa__erro-outros').style.display = 'none';
  }

  exibirMaisRegistros() {
    document.querySelector('.resultado-pesquisa__resultado__exibir-mais').style.display = 'block';
  }

  ocultarMaisRegistros() {
    document.querySelector('.resultado-pesquisa__resultado__exibir-mais').style.display = 'none';
  }

  irParaTopo() {
    document.querySelector('#resultado-pesquisa__conteudo').scroll({ top: 0, behavior: 'smooth' });
  }
}
