import ModalView from '../views/Modal.view';
import Store from '../Store';

export default class ModalController {
  #view;

  constructor() {
    this.#view = new ModalView();
    this.configurarExpandirRecolherIntegra();
    this.configurarFechar();
    this.configurarBotaoSaibaMais();
  }

  configurarExpandirRecolherIntegra() {
    document.querySelector('.modal__body__contribuicao__opcoes--expandir').addEventListener('click', () => this.#view.expandirIntegra());
    document.querySelector('.modal__body__contribuicao__opcoes--recolher').addEventListener('click', () => this.#view.recolherIntegra());
  }

  configurarFechar() {
    document.querySelector('.modal__close').addEventListener('click', () => this.#view.ocultar());
    document.querySelector('.modal__footer__close').addEventListener('click', () => this.#view.ocultar());
    document.querySelector('.modal-sobre__close').addEventListener('click', () => this.#view.ocultarModalSobre());
    document.querySelector('.modal-sobre__footer__close').addEventListener('click', () => this.#view.ocultarModalSobre());
  }

  configurarBotaoSaibaMais() {
    document.getElementById('sobre__button-abrir-modal').addEventListener('click', () => this.#view.exibirModalSobre());
    document.getElementById('link__modal-sobre').addEventListener('click', () => this.#view.exibirModalSobre());
  }
}
