import HttpService from './Http.service';

export default class ContribuicaoService extends HttpService {
  async pesquisar(filtros, offset = 0) {
    const res = await this.post(`contribuicoes/pesquisar?offset=${offset}`, filtros);
    if (res.status === 204) return [];
    const contribuicoes = await res.json();
    return contribuicoes;
  }

  async pesquisarPorId(id) {
    const res = await this.get(`contribuicao/${id}`);
    if (res.status === 204) return null;
    const contribuicao = await res.json();
    return contribuicao;
  }
}
