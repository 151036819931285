/* eslint-disable class-methods-use-this */
import CategoriaView from './Categoria.view';

export default class ModalView {
  exibir(contribuicao) {
    this.setIntroducao(contribuicao.canal.introducao);
    this.setNomeMunicipe(contribuicao.nomeMunicipe);
    this.setCategorias(contribuicao.sugestoes);
    this.setContribuicaoIntegra(contribuicao.integra);
    this.setRespostas(contribuicao.sugestoes);
    document.getElementById('resultado-pesquisa__modal').style.display = 'block';
  }

  expandirIntegra() {
    document.querySelector('.modal__body__contribuicao__integra-completa').style.display = 'block';
    document.querySelector('.modal__body__contribuicao__integra-reduzida').style.display = 'none';
    document.querySelector('.modal__body__contribuicao__opcoes--expandir').style.display = 'none';
    document.querySelector('.modal__body__contribuicao__opcoes--recolher').style.display = 'block';
  }

  setIntroducao(introducao) {
    document.querySelector('.modal__body__introducao__info__local-data').innerHTML = introducao;
  }

  setNomeMunicipe(nomeMunicipe) {
    document.querySelector('.modal__header__saudacao').innerHTML = nomeMunicipe;
  }

  setCategorias(sugestoes) {
    document.querySelector('.modal__body__introducao__sobre__categorias').innerHTML = `
      ${CategoriaView.getCategoriasUnicas(sugestoes)}
    `;
  }

  setContribuicaoIntegra(integra) {
    integra = integra.replace(/\n/g, '<br />');
    this.recolherIntegra();
    document.querySelector('.modal__body__contribuicao__integra-completa').innerHTML = integra;

    const limiteCaracteres = 1000;
    const integraNumCaracteres = integra.length;

    if (integraNumCaracteres > limiteCaracteres) {
      integra = `${integra.substr(0, limiteCaracteres)}...`;
      document.querySelector('.modal__body__contribuicao__opcoes--expandir').style.display = 'block';
    } else {
      document.querySelector('.modal__body__contribuicao__opcoes--expandir').style.display = 'none';
    }
    document.querySelector('.modal__body__contribuicao__integra-reduzida').innerHTML = integra;
  }

  setRespostas(sugestoes) {
    document.querySelector('.modal__body__respostas__conteudo').innerHTML = `
      ${sugestoes.map((sugestao) => `
        <div class="contribuicao__resposta">
          <div class="contribuicao__resposta__subtema">
            <span class="contribuicao__resposta__subtema--label">Tema:</span>
            <span class="contribuicao__resposta__subtema--conteudo">${sugestao.tema.nome}</span>
          </div>
          <div class="contribuicao__resposta__resposta">
            <span class="contribuicao__resposta__devolutiva--label">Resposta:</span>
            <span class="contribuicao__resposta__devolutiva--conteudo">${sugestao.tema.devolutiva.replace(/\n/g, '<br />')}</span>
          </div>
          <div class="contribuicao__resposta__orgaos">
            <div class="contribuicao__resposta__orgaos__subprefeitura">
              <span class="contribuicao__resposta__orgaos__subprefeitura--label">Subprefeitura:</span>
              <span class="contribuicao__resposta__orgaos__subprefeitura--conteudo">${sugestao.subprefeitura.nome}</span>
            </div>
            <div class="contribuicao__resposta__orgaos__secretaria">
              <span class="contribuicao__resposta__orgaos__secretaria--label">Secretaria:</span>
              <span class="contribuicao__resposta__orgaos__secretaria--conteudo">${sugestao.tema.secretaria.sigla}</span>
            </div>
          </div>
          <div class="contribuicao__resposta__eixo">
            <span class="contribuicao__resposta__eixo--label">Eixo:</span>
            <span class="contribuicao__resposta__eixo--conteudo">${sugestao.tema.eixo.nome}</span>
          </div>
        </div>
      `).join('<hr class="contribuicao__resposta--separator">')}
    `;
  }

  ocultar() {
    document.getElementById('resultado-pesquisa__modal').style.display = 'none';
  }

  recolherIntegra() {
    document.querySelector('.modal__body__contribuicao__integra-completa').style.display = 'none';
    document.querySelector('.modal__body__contribuicao__integra-reduzida').style.display = 'block';
    document.querySelector('.modal__body__contribuicao__opcoes--expandir').style.display = 'block';
    document.querySelector('.modal__body__contribuicao__opcoes--recolher').style.display = 'none';
  }

  compartilharTwitter(href) {
    document.querySelector('.modal__header__social__twitter').href = `https://twitter.com/intent/tweet?text=Confira a resposta da Prefeitura de São Paulo para as contribuições da sociedade no Programa de Metas 21-24: url=${href}`;
  }

  compartilharWhatsapp(href) {
    document.querySelector('.modal__header__social__whatsapp').href = `https://api.whatsapp.com/send?text=Confira a resposta da Prefeitura de São Paulo para as contribuições da sociedade no Programa de Metas 21-24: ${href}`;
  }

  compartilharLinkedin(href) {
    document.querySelector('.modal__header__social__linkedin').href = `https://www.linkedin.com/shareArticle?mini=true&url=${href}`;
  }

  compartilharFacebook(href) {
    document.querySelector('.modal__header__social__facebook').href = `https://www.facebook.com/share.php?u='${href}'&quote=Confira a resposta da Prefeitura de São Paulo para as contribuições da sociedade no Programa de Metas 21-24:`;
  }

  compartilharMobile(href) {
    const shareData = {
      title: 'Devolutivas Programa de Metas',
      text: 'Confira a resposta da Prefeitura de São Paulo para as contribuições da sociedade no Programa de Metas 21-24.',
      url: href
    };

    document.querySelector('.modal__header__social__mobile').addEventListener('click', async () => {
      if (navigator.share) {
        try {
          await navigator.share(shareData);
        } catch (err) {
          console.error(err.error);
        }
      } else {
        alert('Função compartilhar não suportada no seu navegador!');
      }
    });
  }

  exibirModalSobre() {
    document.getElementById('sobre__modal').style.display = 'block';
  }

  ocultarModalSobre() {
    document.getElementById('sobre__modal').style.display = 'none';
  }
}
