/* eslint-disable class-methods-use-this */
import SidebarPesquisarView from '../views/SidebarPesquisar.view';

export default class SidebarPesquisarController {
  constructor() {
    this.configurar();
  }

  configurar() {
    document.querySelector('.sidebar-pesquisar__button--open').addEventListener('click', () => SidebarPesquisarView.toggle());
    document.querySelector('.sidebar-pesquisar__button--close').addEventListener('click', () => SidebarPesquisarView.toggle());
  }
}
