/* eslint-disable class-methods-use-this */
export default class LoadingView {
  static hide() {
    document.querySelector('.loading').style.display = 'none';
  }

  static show() {
    document.querySelector('.loading').style.display = 'flex';
  }
}
