/* eslint-disable class-methods-use-this */
import SidebarView from '../views/Sidebar.view';

export default class SidebarController {
    #view;

    constructor() {
      this.#view = new SidebarView();
      this.configurar();
    }

    configurar() {
      document.querySelector('.sidebar__button--open').addEventListener('click', () => this.#view.toggle());
      document.querySelector('.sidebar__button--close').addEventListener('click', () => this.#view.toggle());
    }
}
