/* eslint-disable class-methods-use-this */
import Secretaria from '../model/Secretaria';
import SecretariaService from '../services/Secretaria.service';
import Store from '../Store';

export default class SecretariaController {
  #service;

  constructor() {
    this.#service = new SecretariaService();
  }

  async carregarDados() {
    const secretarias = [];
    const res = await this.#service.listarTodos();
    res.forEach((row) => {
      const secretaria = new Secretaria(row.id, row.sigla);
      secretarias.push(secretaria);
    });
    Store.data.secretarias = secretarias;
  }

  async criarComponentes() {
    import('../components/Secretaria.component');
  }
}
