/* eslint-disable class-methods-use-this */
import Categoria from '../model/Categoria';
import CategoriaService from '../services/Categoria.service';
import Store from '../Store';

export default class CategoriaController {
  #service;

  constructor() {
    this.#service = new CategoriaService();
  }

  async carregarDados() {
    const categorias = [];
    const res = await this.#service.listarTodos();
    res.forEach((row) => {
      const categoria = new Categoria(row.id, row.nome);
      categorias.push(categoria);
    });
    Store.data.categorias = categorias;
  }

  async criarComponentes() {
    import('../components/Categoria.component');
  }
}
