export default class Subprefeitura {
  #id;

  #nome;

  constructor(id, nome) {
    this.#id = id;
    this.#nome = nome;
  }

  get id() {
    return this.#id;
  }

  get nome() {
    return this.#nome;
  }
}
