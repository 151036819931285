import Store from '../Store';
import LandingPageView from '../views/LandingPage.view';

export default class LandingPageController {
  #view;

  constructor() {
    this.#view = new LandingPageView();
    Store.data.filtros = {};
    Store.data.offset = 0;
    Store.data.contribuicoes = [];
    Store.error = false;
  }
}
