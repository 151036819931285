/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
import ContribuicaoService from '../services/Contribuicao.service';
import LoadingView from '../views/Loading.view';
import ModalView from '../views/Modal.view';
import ResultadoPesquisaView from '../views/ResultadoPesquisa.view';
import SidebarPesquisarView from '../views/SidebarPesquisar.view';
import Store from '../Store';

export default class PesquisaController {
  #contribuicaoService;

  #modalView;

  #resultadoPesquisaView;

  id;

  constructor() {
    this.#contribuicaoService = new ContribuicaoService();
    this.#modalView = new ModalView();
    this.#resultadoPesquisaView = new ResultadoPesquisaView();
    this.configurarBotoesDePesquisa();
    this.configurarLinkPesquisarMais();
    this.#configurarParametrosURL();
  }

  configurarBotoesDePesquisa() {
    document.getElementById('pesquisa__button').addEventListener('click', async (evt) => this.pesquisar(evt));
    document.getElementById('resultado-pesquisa__button').addEventListener('click', async (evt) => this.pesquisar(evt));
    document.getElementById('sidebar-resultado-pesquisa__button').addEventListener('click', async (evt) => {
      SidebarPesquisarView.toggle();
      this.pesquisar(evt);
    });
  }

  configurarLinkModal() {
    const links = document.getElementsByClassName('contribuicao__footer--link');
    links.forEach((link) => link.addEventListener('click', (e) => {
      const idContribuicao = parseInt(e.target.attributes['data-id'].value, 10);
      const contribuicao = Store.data.contribuicoes.filter((contr) => contr.id === idContribuicao)[0];
      this.#modalView.exibir(contribuicao);

      this.#modalView.compartilharTwitter(`${window.location.href}?id=${idContribuicao}`);
      this.#modalView.compartilharWhatsapp(`${window.location.href}?id=${idContribuicao}`);
      this.#modalView.compartilharLinkedin(`${window.location.href}?id=${idContribuicao}`);
      this.#modalView.compartilharFacebook(`${window.location.href}?id=${idContribuicao}`);

      this.#modalView.compartilharMobile(`${window.location.href}?id=${idContribuicao}`);
    }));
  }

  configurarLinkPesquisarMais() {
    document.querySelector('.resultado-pesquisa__resultado__exibir-mais').addEventListener('click', async (evt) => this.pesquisar(evt, false));
  }

  async pesquisar(evt, novaPesquisa = true) {
    evt.preventDefault();
    Store.error = false;
    if (novaPesquisa) {
      Store.data.contribuicoes = [];
      Store.data.offset = 0;
    }

    try {
      LoadingView.show();
      const contribuicoes = await this.#contribuicaoService.pesquisar(Store.data.filtros, Store.data.offset);
      Store.data.contribuicoes = [...Store.data.contribuicoes, ...contribuicoes];
      Store.data.offset = contribuicoes.length > 0 ? Store.data.contribuicoes.length + contribuicoes.length : 0;
    } catch (err) {
      Store.error = true;
      console.error(err);
    } finally {
      LoadingView.hide();
      this.#resultadoPesquisaView.show();
      if (novaPesquisa) this.#resultadoPesquisaView.irParaTopo();
      this.configurarLinkModal();
    }
  }

  #configurarParametrosURL() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // eslint-disable-next-line prefer-destructuring
    if (params.id) {
      const match = params.id.match(/^[0-9]*$/);
      if (match) this.id = parseInt(match[0], 10);
    }
  }

  async pesquisarPorId(id) {
    try {
      LoadingView.show();
      const contribuicao = await this.#contribuicaoService.pesquisarPorId(id);
      if (contribuicao) {
        Store.data.contribuicoes.push(contribuicao);
        Store.data.offset = 1;
      }
    } catch (err) {
      Store.error = true;
      console.error(err);
    } finally {
      LoadingView.hide();
      this.#resultadoPesquisaView.show();
      if (Store.data.contribuicoes.length > 0) {
        this.configurarLinkModal();
        const contribuicao = Store.data.contribuicoes[0];
        this.#modalView.exibir(contribuicao);
      }
    }
  }
}
